import { ethers } from 'ethers'
import { stakingABI, tokenABI, lpTokenABI, swapABI, busdABI } from './abi'

export default class ContractService {
    constructor (context, providerInstance) {
        this.context = context
        const provider = new ethers.providers.Web3Provider(providerInstance)
        const signer = provider.getSigner()
        this.signer = signer
        this.stakingContract = new ethers.Contract(context.$config.STAKING, stakingABI, signer)
        this.tokenContract = new ethers.Contract(context.$config.TOKEN, tokenABI, signer)
        this.lpTokenContract = new ethers.Contract(context.$config.LP_TOKEN, lpTokenABI, signer)
        this.swap = new ethers.Contract(context.$config.SWAP, swapABI, signer)
    }

    async connectWalletProviderAndGetCurrentAddress (walletProvider) {
        this.provider = new ethers.providers.Web3Provider(walletProvider)
        this.signer = this.provider.getSigner()
        this.stakingContract = new ethers.Contract(this.context.$config.STAKING, stakingABI, this.signer)
        this.tokenContract = new ethers.Contract(this.context.$config.TOKEN, tokenABI, this.signer)
        this.lpTokenContract = new ethers.Contract(this.context.$config.LP_TOKEN, lpTokenABI, this.signer)
        this.busdTokenContract = new ethers.Contract(this.context.$config.BUSD_TOKEN, busdABI, this.signer)
        this.swap = new ethers.Contract(this.context.$config.SWAP, swapABI, this.signer)

        const accounts = await this.provider.provider.request({ method: 'eth_requestAccounts' })
        this.currentAccount = accounts[0]
        return this.currentAccount
    }

    async getCurrencyBalanceAndSaveToStore () {
        if (this.currentAccount) {
            const currencyBalance = await this.busdTokenContract.balanceOf(this.currentAccount)
            this.context.$store.commit('setCurrencyBalance', currencyBalance)
            const currencyTotalSupply = await this.busdTokenContract.totalSupply()
            this.context.$store.commit('setCurrencyTotalSupply', currencyTotalSupply)
        }
    }

    async getTokensBalanceAndSaveToStore () {
        if (this.currentAccount) {
            const tokensBalance = await this.tokenContract.balanceOf(this.currentAccount)
            this.context.$store.commit('setTokensBalance', tokensBalance)
            const tokensTotalSupply = await this.tokenContract.totalSupply()
            this.context.$store.commit('setTokensTotalSupply', tokensTotalSupply)
        }
    }

    async getLiquidityTokensAmountAndSaveToStore () {
        const totalSupply = await this.lpTokenContract.totalSupply()
        this.context.$store.commit('liquidity/setLiquidityTokensAmount', totalSupply)
    }

    async getUserLiquidityTokensBalanceAndSaveToStore () {
        if (this.currentAccount) {
            const lpTokensBalance = await this.lpTokenContract.balanceOf(this.currentAccount)
            this.context.$store.commit('liquidity/setCurrentUserLiquidityTokensBalance', lpTokensBalance)
        }
    }

    async getTokensAllowanceAndSaveToStore () {
        if (this.currentAccount) {
            const tokensAllowance = await this.tokenContract.allowance(this.currentAccount, this.context.$config.SWAP)
            const busdAllowance = await this.busdTokenContract.allowance(this.currentAccount, this.context.$config.SWAP)
            this.context.$store.commit('liquidity/setTokensAllowance', tokensAllowance)
            this.context.$store.commit('liquidity/setBUSDTokensAllowance', busdAllowance)
        }
    }

    async getLiquidityTokensAllowanceAndSaveToStore () {
        if (this.currentAccount) {
            const liquidityTokensAllowance = await this.lpTokenContract.allowance(this.currentAccount, this.context.$config.SWAP)
            this.context.$store.commit('liquidity/setLiquidityTokensAllowance', liquidityTokensAllowance)
        }
    }

    async saveLiquidityPairReservesToStore () {
        const reserves = await this.lpTokenContract.getReserves()
        this.context.$store.commit('liquidity/setReserves', reserves)
    }

    async getLiquidityTokensAllowanceForStakingAndSaveToStore () {
        const allowance = await this.lpTokenContract.allowance(this.currentAccount, this.context.$config.STAKING)
        this.context.$store.commit('staking/setAllowance', allowance)
    }

    async getStakingStatisticsAndSaveToStore () {
        const stakingStatistics = await this.stakingContract.getStakingStatistics(this.currentAccount)
        this.context.$store.commit('staking/setStakingStatistics', stakingStatistics)
    }

    addLiquidityETH (tokensForLiquidityDesired, currencyForLiquidityDesired, tokensForLiquidityMin, currencyForLiquidityMin, deadline) {
        if (this.currentAccount) {
            return this.swap.addLiquidity(this.context.$config.TOKEN, this.context.$config.BUSD_TOKEN, tokensForLiquidityDesired, currencyForLiquidityDesired, tokensForLiquidityMin, currencyForLiquidityMin, this.currentAccount, deadline)
        }
    }

    removeLiquidityETH (liquidity, amountTokenMin, amountCurrencyMin, deadline) {
        if (this.currentAccount) {
            return this.swap.removeLiquidity(this.context.$config.BUSD_TOKEN, this.context.$config.TOKEN, liquidity, amountCurrencyMin, amountTokenMin, this.currentAccount, deadline)
        }
    }

    tokensApprove (amount) {
        return this.tokenContract.approve(this.context.$config.SWAP, amount)
    }

    lpTokensApprove (amount) {
        return this.lpTokenContract.approve(this.context.$config.SWAP, amount)
    }

    busdTokenApprove (amount) {
        return this.busdTokenContract.approve(this.context.$config.SWAP, amount)
    }

    approveLiquidityTokensForStaking (amount) {
        return this.lpTokenContract.approve(this.context.$config.STAKING, amount)
    }

    isAccountConnected () {
        return !!this.currentAccount
    }

    stake (amount) {
        return this.stakingContract.stake(amount)
    }

    unstake (amount) {
        return this.stakingContract.unstake(amount)
    }

    collectStakingRewards () {
        return this.stakingContract.withdrawTokensReward()
    }

    onAccountsChanged (callback) {
        this.provider.provider.on('accountsChanged', callback)
    }

    async getContractCommonInfoAndSaveToStore() {
        const DIVIDER = await this.stakingContract.DIVIDER();
        const MULTIPLIER = await this.stakingContract.MULTIPLIER();
        const TIME_STEP = await this.stakingContract.TIME_STEP();

        this.context.$store.commit('setCommonContractInfo', { DIVIDER, MULTIPLIER, TIME_STEP })
    }
}

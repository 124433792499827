import { BigNumber, ethers } from 'ethers'

const PERCENT_MULTIPLIER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const ZERO_BN = ethers.BigNumber.from('0')

export const LiquidityState = {
    namespaced: true,
    state: () => ({
        currencyReserve: BigNumber.from('0'),
        tokensReserve: BigNumber.from('0'),
        currentUserLiquidityTokensBalance: BigNumber.from('0'),
        liquidityTokensAmount: BigNumber.from('0'),
        userToSwapTokensAllowance: BigNumber.from('0'),
        busdTokensAllowance: BigNumber.from('0'),
        userToSwapLiquidityTokensAllowance: BigNumber.from('0')
    }),
    getters: {
        getLiquidityTokensAmount (state) {
            return state.liquidityTokensAmount
        },
        __userLiquidityPortionPercent (state) {
            return state.liquidityTokensAmount.eq(ZERO_BN) ? ZERO_BN : state.currentUserLiquidityTokensBalance.mul(PERCENT_MULTIPLIER).div(state.liquidityTokensAmount)
        },
        currentUserCurrencyBalance: function (state, getters) {
            return state.currencyReserve.mul(getters.__userLiquidityPortionPercent).div(PERCENT_MULTIPLIER)
        },
        currentUserTokensBalance: function (state, getters) {
            return state.tokensReserve.mul(getters.__userLiquidityPortionPercent).div(PERCENT_MULTIPLIER)
        },
        liquidityTokensBalanceFormated: function (state) {
            return ethers.utils.formatEther(state.currentUserLiquidityTokensBalance)
        }
    },
    mutations: {
        setReserves: function (state, reserves) {
            state.currencyReserve = reserves[1]
            state.tokensReserve = reserves[0]
        },
        setCurrentUserLiquidityTokensBalance (state, currentUserLiquidityTokensBalance) {
            state.currentUserLiquidityTokensBalance = currentUserLiquidityTokensBalance
        },
        setLiquidityTokensAmount: function (state, liquidityTokensAmount) {
            state.liquidityTokensAmount = liquidityTokensAmount
        },
        setTokensAllowance: function (state, allowance) {
            state.userToSwapTokensAllowance = allowance
        },
        setBUSDTokensAllowance: function (state, allowance) {
            state.busdTokensAllowance = allowance
        },
        setLiquidityTokensAllowance: function (state, allowance) {
            state.userToSwapLiquidityTokensAllowance = allowance
        }
    }
}

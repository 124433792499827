import { BigNumber, ethers } from 'ethers'
import Vue from 'vue'
import Vuex from 'vuex'
import { LiquidityState } from './modules/liquidity'
import { StakingState } from './modules/staking'

Vue.use(Vuex)

export default new Vuex.Store({
    state: () => ({
        currencyBalance: BigNumber.from('0'),
        tokensBalance: BigNumber.from('0'),
        currencyTotalSupply: BigNumber.from('0'),
        tokensTotalSupply: BigNumber.from('0'),
        lang: 'en',
        DIVIDER: BigNumber.from('0'),
        MULTIPLIER: BigNumber.from('0'),
        TIME_STEP: BigNumber.from('0'),
        isWarningPopupAvailable: false
    }),
    mutations: {
        setCurrencyBalance: function (state, currencyBalance) {
            state.currencyBalance = currencyBalance
        },
        setTokensBalance: function (state, tokensBalance) {
            state.tokensBalance = tokensBalance
        },
        setLang: function (state, lang) {
            state.lang = lang
        },
        setCommonContractInfo: function (state, commonInfo) {
            const { DIVIDER, MULTIPLIER, TIME_STEP } = commonInfo
            state.DIVIDER = DIVIDER
            state.MULTIPLIER = MULTIPLIER
            state.TIME_STEP = TIME_STEP
        },
        setCurrencyTotalSupply: function (state, currencyTotalSupply) {
            state.currencyTotalSupply = currencyTotalSupply
        },
        setTokensTotalSupply: function (state, tokensTotalSupply) {
            state.tokensTotalSupply = tokensTotalSupply
        },
        setWarningFlag: function state(state, flag) {
            state.isWarningPopupAvailable = flag
        }
    },
    getters: {
        currencyBalanceFormated: function (state) {
            return ethers.utils.formatEther(state.currencyBalance)
        },
        tokensBalanceFormated: function (state) {
            return ethers.utils.formatEther(state.tokensBalance)
        },
        tokensBalanceBigNumber: function (state) {
            return state.tokensBalance
        }
    },
    modules: {
        liquidity: LiquidityState,
        staking: StakingState
    }
})

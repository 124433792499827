<template>
    <div class="content-select">
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}: </div>
                <div class="value">{{ Number.parseFloat(stakedFormatted).toFixed($digitsAfterDot) }} RST-LP</div>
            </div>
            <div class="input-group"><input v-model="amountToUnstake" id="bnbDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control"><span id="inputGroup-sizing-default"
                    class="input-group-text">RST-LP</span></div>
        </div>
        <div class="container-select-amount">
            <button class="btn" @click="setAmountToUnstakeByPercent25">25%</button>
            <button class="btn" @click="setAmountToUnstakeByPercent50">50%</button>
            <button class="btn" @click="setAmountToUnstakeByPercent75">75%</button>
            <button class="btn" @click="setAmountToUnstakeByPercent100">100%</button>
        </div>
        <div class="content-select-footer">
            <button @click="unstake">{{ $t("messages.Unstake") }}</button>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'RemoveStakingComponent',
    data: function () {
        return {
            amountToUnstake: 0
        }
    },
    watch: {
        amountToUnstake: function (val) {
            if (typeof val == 'string') {
                if (val[0] == '0' && val.length > 1) {
                    this.amountToUnstake = Number.parseFloat(val.slice(1))
                } else {
                    this.amountToUnstake = Number.parseFloat(val)
                }
            } else {
                this.amountToUnstake = val
            }
        }
    },
    computed: {
        ...mapState('staking', ['staked']),
        ...mapGetters('staking', ['stakedFormatted'])
    },
    methods: {
        unstake: function () {
            const amountToUnstake = ethers.utils.parseEther(Number.parseFloat(this.amountToUnstake).toFixed(18))
            if (amountToUnstake.lte(this.staked)) {
                this.$contract.unstake(amountToUnstake).then(this.__onTransactionDone)
            } else {
                this.$store.commit('setWarningFlag', true)
                setTimeout(() => this.$store.commit('setWarningFlag', false), 5000)
            }
        },
        __onTransactionDone (tx) {
            tx.wait().then(() => {
                this.amountToUnstake = 0
                this.$parent.$emit('onTransactionDone')
            })
        },
        __setAmountToUnstakeByPercent: function (percent) {
            const amountToUnstake = Number.parseFloat(this.stakedFormatted) * percent
            this.$set(this, 'amountToUnstake', amountToUnstake)
        },
        setAmountToUnstakeByPercent25: function () {
            this.__setAmountToUnstakeByPercent(0.25)
        },
        setAmountToUnstakeByPercent50: function () {
            this.__setAmountToUnstakeByPercent(0.5)
        },
        setAmountToUnstakeByPercent75: function () {
            this.__setAmountToUnstakeByPercent(0.75)
        },
        setAmountToUnstakeByPercent100: function () {
            this.__setAmountToUnstakeByPercent(1)
        }
    }
}
</script>
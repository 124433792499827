import Vue from 'vue'
import VueRouter from 'vue-router'
import StatisticsPage from '@/components/StatisticsPage.vue'
import LiquidityPage from '@/components/LiquidityPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        name: 'statistics',
        component: StatisticsPage
    },
    {
        path: '/staking',
        name: 'staking',
        component: LiquidityPage
    }
]

export default new VueRouter({
    mode: "history",
    base: process.env.BASE_URL || "localhost",
    routes,
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }

        return {x: 0, y: 0}
    }
})

import { ethers } from 'ethers'

const ZERO_BN = ethers.BigNumber.from('0')
const TEN_BN = ethers.BigNumber.from('10')

function detectAnyProvider () {
    return new Promise((resolve, reject) => {
        let connectAttemptions = 0
        let maximumConnectionAttemptions = 10
        let nextConnectionTimeout = 100

        const nextTry = () => {
            if (window.ethereum) {
                resolve(window.ethereum)
            } else if (window.BinanceChain) {
                resolve(window.BinanceChain)
            } else {
                connectAttemptions++
                if (connectAttemptions < maximumConnectionAttemptions) {
                    setTimeout(nextTry, nextConnectionTimeout)
                }
                else {
                    reject()
                }
            }
        }

        nextTry()
    })
}

function waitingProvider (providerKey) {
    return new Promise((resolve, reject) => {
        let connectAttemptions = 0
        let maximumConnectionAttemptions = 10
        let nextConnectionTimeout = 500

        const nextTry = () => {
            if (window[providerKey]) {
                resolve(window[providerKey])
            } else {
                connectAttemptions++
                if (connectAttemptions < maximumConnectionAttemptions) {
                    setTimeout(nextTry, nextConnectionTimeout)
                }
                else {
                    reject()
                }
            }
        }

        nextTry()
    })
}

function divBigNumbersByConvertingToIntegers (bn1, bn2) {
    if (bn2.eq(ZERO_BN)) {
        return 0
    }

    let n1, n2

    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
            n1 = Number.parseFloat(ethers.utils.formatEther(bn1))
            n2 = Number.parseFloat(ethers.utils.formatEther(bn2))

            break 
        } catch {
            bn1 = bn1.div(TEN_BN)
            bn2 = bn2.div(TEN_BN)
        }
    }

    return n1 / n2
}

export { detectAnyProvider, waitingProvider, divBigNumbersByConvertingToIntegers }

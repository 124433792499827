<template>
    <div class="content-select">
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}:</div>
                <div class="value">{{ Number.parseFloat(currencyBalanceFormated).toFixed($digitsAfterDot) }} BUSD</div>
            </div>
            <div class="input-group"><input v-model="currencyDesiredAmount" @input="currencyAmountChangedHandler" id="currencyDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control"><span id="inputGroup-sizing-default"
                    class="input-group-text">BUSD</span></div>
        </div>
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}: </div>
                <div class="value">{{ Number.parseFloat(tokensBalanceFormated).toFixed($digitsAfterDot) }} RST</div>
            </div>
            <div class="input-group"><input v-model="tokensDesiredAmount" @input="tokensAmountChangedHandler" id="tokensDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control"><span id="inputGroup-sizing-default"
                    class="input-group-text">RST</span></div>
        </div>
        <div class="content-select-footer">
            <button @click="__addLiquidity" v-if="isAllowedToAddLiqidity">{{ $t("messages.Add") }}</button>
            <button @click="__tokensApprove" v-else>{{ $t("messages.Approve") }}</button>
            <a href="https://pancakeswap.finance/swap?inputCurrency=0xD70B38dEDD42B4c22A7e0D8A176fb0957C5fbAC3" target="_blank">{{ $t("messages.goToExchange") }}</a>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { divBigNumbersByConvertingToIntegers } from '@/services/provider.utils'

const MIN_DIFF_PERCENT = 0.05
const DEADLINE_IN_MINUTES = 20
const ZERO_BN = ethers.BigNumber.from('0')
const ONE_BN = ethers.BigNumber.from('1')

export default {
    name: 'AddLiquidityComponent',
    data: function () {
        return {
            currencyDesiredAmount: 0,
            tokensDesiredAmount: 0
        }
    },
    watch: {
        currencyDesiredAmount: function (val) {
            if (typeof val == 'string') {
                if (val[0] == '0' && val.length > 1) {
                    this.currencyDesiredAmount = Number.parseFloat(val.slice(1))
                } else {
                    this.currencyDesiredAmount = Number.parseFloat(val)
                }
            } else {
                this.currencyDesiredAmount = val
            }
        },
        tokensDesiredAmount: function (val) {
            if (typeof val == 'string') {
                if (val[0] == '0' && val.length > 1) {
                    this.tokensDesiredAmount = Number.parseFloat(val.slice(1))
                } else {
                    this.tokensDesiredAmount = Number.parseFloat(val)
                }
            } else {
                this.tokensDesiredAmount = val
            }
        }
    },
    computed: {
        ...mapState(['currencyBalance', 'tokensBalance', 'currencyTotalSupply', 'tokensTotalSupply']),
        ...mapGetters(['currencyBalanceFormated', 'tokensBalanceFormated']),
        ...mapMutations(['setWarningFlag']),
        ...mapState('liquidity', ['currencyReserve', 'tokensReserve', 'userToSwapTokensAllowance', 'userToSwapLiquidityTokensAllowance', 'busdTokensAllowance']),
        currencyPerTokens () {
            const currencyReserve = this.currencyReserve.eq(ZERO_BN) ? ONE_BN : this.currencyReserve;
            return divBigNumbersByConvertingToIntegers(this.tokensReserve, currencyReserve)
        },
        tokensPerCurrency () {
            const tokensReserve = this.tokensReserve.eq(ZERO_BN) ? ONE_BN : this.tokensReserve;
            return divBigNumbersByConvertingToIntegers(this.currencyReserve, tokensReserve)
        },
        sendTransactionButtonContent () {
            if (this.userToSwapTokensAllowance.gte(ethers.utils.parseEther(Number.parseFloat(this.tokensDesiredAmount).toFixed(18)))) {
                return this.$t("messages.Add")
            } else {
                return this.$t("messages.Approve")
            }
        },
        isAllowedToAddLiqidity () {
            return this.userToSwapTokensAllowance.gte(ethers.utils.parseEther(Number.parseFloat(this.tokensDesiredAmount).toFixed(18)))
                    && this.busdTokensAllowance.gte(ethers.utils.parseEther(Number.parseFloat(this.currencyDesiredAmount).toFixed(18)))
        }
    },
    methods: {
        currencyAmountChangedHandler: function () {
            this.$set(this, 'tokensDesiredAmount', this.currencyDesiredAmount * this.currencyPerTokens)
        },
        tokensAmountChangedHandler: function () {
            this.$set(this, 'currencyDesiredAmount', this.tokensDesiredAmount * this.tokensPerCurrency)
        },
        __addLiquidity: async function () {
            const currencyForLiquidityDesired = ethers.utils.parseEther(Number.parseFloat(this.currencyDesiredAmount).toFixed(18))
            const currencyForLiquidityMin = ethers.utils.parseEther(Number.parseFloat(this.currencyDesiredAmount - this.currencyDesiredAmount * MIN_DIFF_PERCENT).toFixed(18))
            const tokensForLiquidityDesired = ethers.utils.parseEther(Number.parseFloat(this.tokensDesiredAmount).toFixed(18))
            const tokensForLiquidityMin = ethers.utils.parseEther(Number.parseFloat(this.tokensDesiredAmount - this.tokensDesiredAmount * MIN_DIFF_PERCENT).toFixed(18))
            const deadline = Number.parseInt(Date.now() / 1000) + DEADLINE_IN_MINUTES * 60
            
            if (tokensForLiquidityDesired.lte(this.tokensBalance) && currencyForLiquidityDesired.lte(this.currencyBalance)) {
                const tx = await this.$contract.addLiquidityETH(tokensForLiquidityDesired, currencyForLiquidityDesired, tokensForLiquidityMin, currencyForLiquidityMin, deadline)
                await tx.wait()
                this.currencyDesiredAmount = 0
                this.tokensDesiredAmount = 0
                this.$parent.$emit('onTransactionDone')
            } else {
                this.$store.commit('setWarningFlag', true)
                setTimeout(() => this.$store.commit('setWarningFlag', false), 5000)
            }
        },
        __tokensApprove: async function () {
            const tokensApproveTransaction = await this.$contract.tokensApprove(this.tokensTotalSupply)
            const currencyApproveTransaction = await this.$contract.busdTokenApprove(this.currencyTotalSupply)
            await tokensApproveTransaction.wait()
            await currencyApproveTransaction.wait()
            this.$parent.$emit('onTransactionDone')
        }
    }
}
</script>
<template>
    <div class="container ">
        <div class="screen liquidity-screen" id="liquidity-screen">
            <h1>{{ $t("messages.Liquidity") }}</h1>
            <div class="screen_block two_blocks">
                <div>
                    <h3>{{ $t("messages.liquidityDescription") }}</h3>
                        <div class="container_table">
                            <table class="mb-2 table table-striped border-bottom">
                                <tbody>
                                    <tr>
                                        <td>{{ $t("messages.myPoolOfTokens") }}:</td>
                                        <td class="bold">{{ Number.parseFloat(liquidityTokensBalanceFormated).toFixed($digitsAfterDot) }} RST-LP <span class="nearly">≈ {{ Number.parseFloat(liquidityTokensPoolToUsd).toFixed($digitsAfterDot) }} $</span></td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("messages.Liquidity") }}:</td>
                                        <td>≈ {{ Number.parseFloat(liquidityToUsd).toFixed($digitsAfterDot) }} $</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("messages.pairAddress") }}:</td>
                                        <td>
                                            <a :href="'https://bscscan.com/address/' + pairAddress" target="_blank">{{ pairAddressShorter }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("messages.tokenAddress") }}:</td>
                                        <td>
                                            <a :href="'https://bscscan.com/address/' + tokenAddress" target="_blank">{{ tokenAddressShorter }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <a href="https://pancakeswap.finance/swap?inputCurrency=0xD70B38dEDD42B4c22A7e0D8A176fb0957C5fbAC3" target="_blank" class="link">{{ $t("messages.getToken") }}</a>
                        <a href="javascript:void(0);" class="link" @click="watchTokenAsset">{{ $t("messages.addTokenToWallet") }}</a>
                        <a href="javascript:void(0);" class="link" @click="watchLpTokenAsset">{{ $t("messages.addLpTokenToWallet") }}</a>
                </div>
                <liquidity-panel-component @onTransactionDone="onTransactionDone" />
            </div>
        </div>
        <div class="screen staking-screen"  id="staking-screen">
            <h1 id="staking">{{ $t("messages.Staking") }}</h1>
            <div class="screen_block two_blocks">
            <staking-panel-component @onTransactionDone="onTransactionDone" />
                <div>
                    <ul class="info-list">
                        <li>
                            <div>{{ $t("messages.stakedTokens") }}:</div>
                            <div>{{ Number.parseFloat(stakedFormatted).toFixed($digitsAfterDot) }} RST-LP</div>
                        </li>
                        <li>
                            <div>{{ $t("messages.Earned") }}:</div>
                            <div>{{ Number.parseFloat(availableRewardFormatted).toFixed($digitsAfterDot) }} RST</div>
                        </li>
                        <li>
                            <div>{{ $t("messages.stakingProfitability") }}:</div>
                            <div>{{ Number.parseFloat(stakingProfitability).toFixed($digitsAfterDot) }}%</div>
                        </li>
                    </ul>
                    <h3>{{ $t("messages.feeAlert") }}</h3>
                    <div class="block">
                        <h2>{{ $t("messages.tokensEarned") }}</h2>
                        <h1>{{ Number.parseFloat(availableRewardFormatted).toFixed($digitsAfterDot) }}</h1>
                            <button @click="collectStakingRewards" class="button-collect">{{ $t("messages.Collect") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import { mapState, mapGetters } from 'vuex'
 import { ethers } from 'ethers'
import LiquidityPanelComponent from '@/components/liquidity/LiquidityPanelComponent.vue'
import StakingPanelComponent from '@/components/staking/StakingPanelComponent.vue'

const PERCENT_MULTIPLIER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const ZERO_BN = ethers.BigNumber.from('0')
const ONE_BN = ethers.BigNumber.from('1')
const SECONDS_IN_YEAR = 31536000

function getShorterAddress (address, tailsLength = 4) {
    return address.substring(0, tailsLength) + '...' + address.substring(address.length - tailsLength, address.length)
}

export default {
    name: 'LiquidityPage',
    components: {
        LiquidityPanelComponent,
        StakingPanelComponent,
    },
   computed: {
       ...mapState(['DIVIDER', 'MULTIPLIER', 'TIME_STEP']),
       ...mapState('liquidity', ['currencyReserve', 'tokensReserve']),
       ...mapGetters('staking', ['stakedFormatted', 'availableRewardFormatted']),
       ...mapGetters('liquidity', ['liquidityTokensBalanceFormated', '__userLiquidityPortionPercent', 'getLiquidityTokensAmount']),
      pairAddress: function () {
          return this.$config.LP_TOKEN
      },
      pairAddressShorter: function () {
          return getShorterAddress(this.$config.LP_TOKEN)
      },
      tokenAddress: function () {
          return this.$config.TOKEN
      },
      tokenAddressShorter: function () {
          return getShorterAddress(this.$config.TOKEN)
      },
      currencyPerTokens () {
            const currencyReserve = this.currencyReserve.eq(ZERO_BN) ? ONE_BN : this.currencyReserve;
            return this.tokensReserve.mul(PERCENT_MULTIPLIER).div(currencyReserve)
        },
        tokensPerCurrency () {
            const tokensReserve = this.tokensReserve.eq(ZERO_BN) ? ONE_BN : this.tokensReserve;
            return this.currencyReserve.mul(PERCENT_MULTIPLIER).div(tokensReserve)
        },
      tokenPrice: function () {
          return Number.parseFloat(ethers.utils.formatEther(this.tokensPerCurrency)) / Number.parseFloat(ethers.utils.formatEther(PERCENT_MULTIPLIER))
      },
      liquidityToUsd: function () {
          return (Number.parseFloat(ethers.utils.formatEther(this.currencyReserve)) + Number.parseFloat(ethers.utils.formatEther(this.tokensReserve)) * this.tokenPrice).toFixed(this.$digitsAfterDot)
          // return Number.parseFloat(ethers.utils.formatEther(this.getLiquidityTokensAmount)) * 2
      },
      liquidityTokensPoolToUsd : function () {
          const liquidityCost = this.liquidityToUsd / ethers.utils.formatEther(this.getLiquidityTokensAmount)
          return Number.isNaN(liquidityCost) ? 0 : liquidityCost * this.liquidityTokensBalanceFormated
      },
      stakingProfitability: function () {
          const multiplier = this.MULTIPLIER.toNumber()
          const divider = this.DIVIDER.toNumber()
          const timeStep = this.TIME_STEP.toNumber()

          return multiplier / divider * SECONDS_IN_YEAR / timeStep * 100 * this.tokenPrice / (this.liquidityToUsd / ethers.utils.formatEther(this.getLiquidityTokensAmount))
      }
   },
   methods: {
      onAccountChanged: function () {
        this.$contract.getCurrencyBalanceAndSaveToStore()
        this.$contract.getTokensBalanceAndSaveToStore()
        this.$contract.getUserLiquidityTokensBalanceAndSaveToStore()
        this.$contract.getTokensAllowanceAndSaveToStore()
        this.$contract.getLiquidityTokensAllowanceAndSaveToStore()
        this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore()
        this.$contract.getStakingStatisticsAndSaveToStore()
      },
      onTransactionDone: function () {
        this.$contract.getLiquidityTokensAmountAndSaveToStore()
        this.$contract.saveLiquidityPairReservesToStore()

        if (this.$contract.isAccountConnected()) {
            this.$contract.getCurrencyBalanceAndSaveToStore()
            this.$contract.getTokensBalanceAndSaveToStore()
            this.$contract.getUserLiquidityTokensBalanceAndSaveToStore()
            this.$contract.getTokensAllowanceAndSaveToStore()
            this.$contract.getLiquidityTokensAllowanceAndSaveToStore()
            this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore()
            this.$contract.getStakingStatisticsAndSaveToStore()
        }
      },
      collectStakingRewards: function () {
          this.$contract.collectStakingRewards().then(tx => {
              tx.wait().then(this.onTransactionDone)
          })
      },
      watchTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.TOKEN,
                    symbol: 'RST',
                    decimals: 18
                }
            }
        })
      },
      watchLpTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.LP_TOKEN,
                    symbol: 'RST-LP',
                    decimals: 18
                }
            }
        })
      }
   }
}
</script>

<template>
    <div class="content-select">
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}: </div>
                <div class="value">{{ Number.parseFloat(liquidityTokensBalanceFormated).toFixed($digitsAfterDot) }} RST-LP</div>
            </div>
            <div class="input-group"><input v-model="amountToStake" id="bnbDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control"><span id="inputGroup-sizing-default"
                    class="input-group-text">RST-LP</span></div>
        </div>
        <div class="content-select-footer">
            <button v-if="isAllowsToStake" @click="stake">{{ $t("messages.Stake") }}</button>
            <button v-else @click="approve">{{ $t("messages.Approve") }}</button>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AddStakingComponent',
    data: function () {
        return {
            amountToStake: 0
        }
    },
    watch: {
        amountToStake: function (val) {
            if (typeof val == 'string') {
                if (val[0] == '0' && val.length > 1) {
                    this.amountToStake = Number.parseFloat(val.slice(1))
                } else {
                    this.amountToStake = Number.parseFloat(val)
                }
            } else {
                this.amountToStake = val
            }
        }
    },
    computed: {
        ...mapState('liquidity', ['currentUserLiquidityTokensBalance', 'liquidityTokensAmount']),
        ...mapState('staking', ['userToContractLiquidityTokensAllowance']),
        ...mapGetters('liquidity', ['liquidityTokensBalanceFormated']),
        amountToStakeParsed () {
            return ethers.utils.parseEther(Number.parseFloat(this.amountToStake || 0).toFixed(18))
        },
        isAllowsToStake () {
            return this.userToContractLiquidityTokensAllowance.gte(this.amountToStakeParsed)
        }
    },
    methods: {
        stake: function () {
            const amountToStake = ethers.utils.parseEther(Number.parseFloat(this.amountToStake).toFixed(18))
            if (amountToStake.lte(this.currentUserLiquidityTokensBalance)) {
                this.$contract.stake(amountToStake).then(this.__onTransactionDone)
            } else {
                this.$store.commit('setWarningFlag', true)
                setTimeout(() => this.$store.commit('setWarningFlag', false), 5000)
            }
        },
        approve: function () {
            this.$contract.approveLiquidityTokensForStaking(this.liquidityTokensAmount).then(this.__onTransactionDone)
        },
        __onTransactionDone: function (tx) {
            tx.wait().then(() => {
                this.amountToStake = 0
                this.$parent.$emit('onTransactionDone')
            })
        }
    }
}
</script>
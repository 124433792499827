<template>
    <div class="liquidity">
        <div class="card card_select">
            <div class="card-body">
                <div class="container-select-block">
                    <div class="btn" v-bind:class="{ active: isAddingLiquidity }" v-on:click="enableAddingLiquidityPanel">{{ $t("messages.Add") }}</div>
                    <div class="btn" v-bind:class="{ active: !isAddingLiquidity }" v-on:click="enableRemoveLiquidityPanel">{{ $t("messages.Remove") }}</div>
                </div>
                <add-liquidity-component v-if="isAddingLiquidity" />
                <remove-liquidity-component v-else />
            </div>
        </div>
    </div>
</template>

<script>
import AddLiquidityComponent from './AddLiquidityComponent.vue'
import RemoveLiquidityComponent from './RemoveLiquidityComponent.vue'

export default {
    name: 'LiquidityPanelComponent',
    components: {
        AddLiquidityComponent,
        RemoveLiquidityComponent
    },
    data: function () {
        return {
            isAddingLiquidity: true
        }
    },
    methods: {
        enableAddingLiquidityPanel: function () {
            this.isAddingLiquidity = true
        },
        enableRemoveLiquidityPanel: function () {
            this.isAddingLiquidity = false
        }
    }
}
</script>

<template>
  <div class="mainWrapper">
    <div class="push" v-if="isWarningPopupAvailable">
      <div class="push-block warning flexInlineBetween">
        <div class="img"><div class="attention"></div></div>
        <div class="text">
          <span class="title"></span> Balance insufficient !!! The amount entered is greater than the available amount.
        </div>
        <button><div class="closeImg" @click="closeWarningPopup"></div></button>
      </div>
    </div>
    <header class="header">
      <div class="mainHeader flexInline">
        <div class="container flexInlineBetween">
          <div class="logoContainer flexInline select">
            <div class="logo grey"></div>
            <div class="logoText"><span>Staking</span><span>Pool</span></div>
            <ul>
              <li class="flexInline">
                <a target="_blank" href="https://infinityincome.io/cabinet"
                  >Infinity Income</a
                >
              </li>
              <li class="flexInline">
                <a target="_blank" href="https://buytokenscheap.infinityincome.io/"
                  >Buy Tokens Cheap</a
                >
              </li>
            </ul>
          </div>
          <div class="menu flexInline">
            <ul class="flexInline lightGreyText">
              <router-link
                tag="li"
                :to="{ name: 'statistics' }"
                class="header-menu__item"
              >
                {{ $t("messages.tokenStatistics") }}
              </router-link>
              <li class="header-menu__item">
                <router-link :to="{ name: 'staking' }">{{
                  $t("messages.Liquidity")
                }}</router-link>
              </li>
              <li class="header-menu__item">
                <router-link to="staking#staking">{{
                  $t("messages.Staking")
                }}</router-link>
              </li>
            </ul>
            <div class="lineLightGrey v"></div>
            <button class="chLang select flexInline">
              <div class="icon" :class="{ enIcon: isEnLang, ruIcon: isRuLang }"></div>
              <ul>
                <li :class="{ active: isEnLang }" @click="setLang('en')">
                  <span class="icon enIcon"></span>
                  <span>English</span>
                </li>
                <li :class="{ active: isRuLang }" @click="setLang('ru')">
                  <span class="icon ruIcon"></span>
                  <span>Русский</span>
                </li>
              </ul>
            </button>
            <div class="lineLightGrey v"></div>
            <connect-button-component
              @onAccountConnected="onAccountChanged"
              ref="connectButton"
              class="btn btnSwitchWallet"
            />
            <div class="lineLightGrey v"></div>
            <a href="https://infinityincome.io/" class="exit"></a>
          </div>
        </div>
      </div>
    </header>
    <div class="menuContainer">
      <div v-if="mobileMenu" class="mainMenuMobileContainer">
        <div class="headerMenu">
          <div class="router-link-active" router-link="">
            <a href="/" class="logoContainer flexInline"
              ><div class="logo"></div>
              <div class="logoText"><span>Staking</span><span>Pool</span></div></a
            >
          </div>
        </div>
        <div class="container">
          <div class="cabinetMenu">
            <ul>
              <router-link
                tag="li"
                :to="{ name: 'statistics' }"
                class="header-menu__item"
              >
                {{ $t("messages.tokenStatistics") }}
              </router-link>
              <li class="header-menu__item">
                <router-link :to="{ name: 'staking' }">{{
                  $t("messages.Liquidity")
                }}</router-link>
              </li>
              <li class="header-menu__item">
                <router-link to="staking#staking">{{
                  $t("messages.Staking")
                }}</router-link>
              </li>
            </ul>
            <button class="btn btnSwitchWallet">Select Wallet</button>
          </div>
          <div class="homeMenu">
            <ul>
              <li><a href="#topPage" class="flexInline"> Main</a></li>
              <li><a href="#aboutPlatform" class="flexInline">About</a></li>
              <li><a href="#howWorkScreen" class="flexInline">How it works</a></li>
              <li><a href="#questionsScreen" class="flexInline">FAQ</a></li>
              <li><a href="#aboutContract" class="flexInline">Contract</a></li>
            </ul>
            <button class="btn btnSwitchWallet">Select Wallet</button>
          </div>
          <div class="blockMenuMobile chLang">
            <div class="title">Language</div>
            <div class="containerItems">
              <a class="wrapItem" :class="{ active: isEnLang }" @click="setLang('en')"
                ><div class="item enIcon"></div></a
              ><a class="wrapItem" :class="{ active: isRuLang }" @click="setLang('ru')"
                ><div class="item ruIcon"></div
              ></a>
            </div>
          </div>
          <div class="blockMenuMobile chLang">
            <div class="title">Channels</div>
            <div class="containerItems">
              <!-- <a
              href="https://www.facebook.com/RelaxStation-Club-751744362186497"
              target="_blank"
              class="facebook"
            ></a> -->
              <a
                href="https://www.instagram.com/infinityincome.io/"
                target="_blank"
                class="instagram"
              ></a>
              <a
                href="https://www.youtube.com/channel/UCBc5qTohoi5qaOGdraMvWcg"
                target="_blank"
                class="youtube"
              ></a>
              <a
                href="https://t.me/InfinityIncome_io"
                target="_blank"
                class="telegram"
              ></a>
              <a
                href="https://t.me/infinityincome_chat"
                target="_blank"
                class="telegram"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <nav class="mobileMenu cabinetMobileMenu">
        <a href="#topPage" class="link toHome">
          <span class="icon home"></span>
          <span>Main</span></a
        >
        <a href="https://infinityincome.io/" class="link"
          ><span class="icon cabinet"> </span><span>Cabinet</span></a
        >
        <div class="link" @click="mobileMenu = !mobileMenu">
          <span class="icon menu"> </span><span>Menu</span>
        </div>
      </nav>
    </div>
    <router-view class="mainContent cabinetContent" :class="{ hidden: mobileMenu }" />
    <div class="footer cabinetFooter">
      <footer>
        <div class="container flexInlineBetween">
          <a href="#topPage" class="logoContainer flexInline"
            ><div class="logo"></div>
            <div class="logoText"><span>Staking</span><span>Pool</span></div></a
          >
          <div class="addressContainer flexColumn">
            <div class="titleAddress">{{ $t("messages.addressSmartContract") }}</div>
            <div class="address">
              <a target="_blank" :href="stakingExplorer">{{ $config.STAKING }}</a>
            </div>
          </div>
          <div class="socialLinkClontainer flexInlineBetween">
            <!-- <a
              href="https://www.facebook.com/RelaxStation-Club-751744362186497"
              target="_blank"
              class="facebook"
            ></a> -->
            <a
              href="https://www.instagram.com/infinityincome.io/"
              target="_blank"
              class="instagram"
            ></a>
            <a
              href="https://www.youtube.com/channel/UCBc5qTohoi5qaOGdraMvWcg"
              target="_blank"
              class="youtube"
            ></a>
            <a href="https://t.me/InfinityIncome_io" target="_blank" class="telegram"></a>
            <a
              href="https://t.me/infinityincome_chat"
              target="_blank"
              class="telegram"
            ></a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import ContractService from "@/services/ContractService";
import ConnectButtonComponent from "./components/ConnectButtonComponent.vue";
import { detectAnyProvider } from "@/services/provider.utils.js";
import "./assets/css/main.css";

const LANG_KEY = "lang";

function getShorterAddress(address, tailsLength = 4) {
  return (
    address.substring(0, tailsLength) +
    "..." +
    address.substring(address.length - tailsLength, address.length)
  );
}

export default {
  name: "App",
  components: {
    ConnectButtonComponent,
  },
  data: function () {
    return {
      isStatisticsPageOpen: true,
      mobileMenu: false,
    };
  },
  watch: {
    $route() {
      this.mobileMenu = false;
    },
  },
  computed: {
    ...mapState(["lang", "isWarningPopupAvailable"]),
    ...mapGetters(["tokensBalanceFormated"]),
    ...mapState("liquidity", ["currencyReserve", "tokensReserve"]),
    ...mapGetters("staking", ["stakedFormatted", "availableRewardFormatted"]),
    ...mapGetters("liquidity", [
      "liquidityTokensBalanceFormated",
      "__userLiquidityPortionPercent",
    ]),
    pairAddress: function () {
      return this.$config.LP_TOKEN;
    },
    pairAddressShorter: function () {
      return getShorterAddress(this.$config.LP_TOKEN);
    },
    tokenAddress: function () {
      return this.$config.TOKEN;
    },
    tokenAddressShorter: function () {
      return getShorterAddress(this.$config.TOKEN);
    },
    isEnLang: function () {
      return this.$i18n.locale == "en";
    },
    isRuLang: function () {
      return this.$i18n.locale == "ru";
    },
    stakingExplorer: function () {
      return `https://bscscan.com/address/${this.$config.STAKING}`;
    },
  },
  methods: {
    onAccountChanged: function () {
      this.$contract.getCurrencyBalanceAndSaveToStore();
      this.$contract.getTokensBalanceAndSaveToStore();
      this.$contract.getUserLiquidityTokensBalanceAndSaveToStore();
      this.$contract.getTokensAllowanceAndSaveToStore();
      this.$contract.getLiquidityTokensAllowanceAndSaveToStore();
      this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore();
      this.$contract.getStakingStatisticsAndSaveToStore();
    },
    onTransactionDone: function () {
      this.$contract.getLiquidityTokensAmountAndSaveToStore();
      this.$contract.saveLiquidityPairReservesToStore();

      if (this.$contract.isAccountConnected()) {
        this.$contract.getCurrencyBalanceAndSaveToStore();
        this.$contract.getTokensBalanceAndSaveToStore();
        this.$contract.getUserLiquidityTokensBalanceAndSaveToStore();
        this.$contract.getTokensAllowanceAndSaveToStore();
        this.$contract.getLiquidityTokensAllowanceAndSaveToStore();
        this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore();
        this.$contract.getStakingStatisticsAndSaveToStore();
      }
    },
    collectStakingRewards: function () {
      this.$contract.collectStakingRewards().then((tx) => {
        tx.wait().then(this.onTransactionDone);
      });
    },
    watchTokenAsset: function () {
      window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: this.$config.TOKEN,
            symbol: "RST",
            decimals: 18,
          },
        },
      });
    },
    watchLpTokenAsset: function () {
      window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: this.$config.LP_TOKEN,
            symbol: "RST-LP",
            decimals: 18,
          },
        },
      });
    },
    setLang(lang) {
      this.$store.commit("setLang", lang);
      this.$i18n.locale = lang;
      localStorage.setItem(LANG_KEY, lang);
    },
    closeWarningPopup () {
      this.$store.commit('setWarningFlag', false)
    }
  },
  mounted() {
    detectAnyProvider().then((providerInstance) => {
      Vue.prototype.$contract = new ContractService(this, providerInstance);
      this.$contract.getContractCommonInfoAndSaveToStore();
      this.$contract.getLiquidityTokensAmountAndSaveToStore();
      this.$contract.saveLiquidityPairReservesToStore();
      this.$refs.connectButton.checkCachedWallet();
    });

    const cachedLang = localStorage.getItem(LANG_KEY);
    if (cachedLang) {
      this.setLang(cachedLang);
    }
  },
};
</script>

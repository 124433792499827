import { BigNumber, ethers } from 'ethers'

export const StakingState = {
    namespaced: true,
    state: () => ({
        userToContractLiquidityTokensAllowance: BigNumber.from('0'),
        availableReward: BigNumber.from('0'),
        accumulatedReward: BigNumber.from('0'),
        withdrawnReward: BigNumber.from('0'),
        staked: BigNumber.from('0'),
        dailyEarnings: BigNumber.from('0')
    }),
    mutations: {
        setAllowance: function (state, allowance) {
            state.userToContractLiquidityTokensAllowance = allowance
        },
        setStakingStatistics: function (state, stakingStatistics) {
            state.availableReward = stakingStatistics[0]
            state.accumulatedReward = stakingStatistics[1]
            state.withdrawnReward = stakingStatistics[2]
            state.staked = stakingStatistics[3]
            state.dailyEarnings = stakingStatistics[4]
        }
    },
    getters: {
        stakedFormatted: function (state) {
            return ethers.utils.formatEther(state.staked)
        },
        availableRewardFormatted: function (state) {
            return ethers.utils.formatEther(state.availableReward)
        }
    }
}
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import store from './store'
import router from './router'
import production from '@/config/production.json'
import { messages } from '@/translations'

Number.prototype.toFixed = function (digitsAfterDot) {
  const multiplier = Math.pow(10, digitsAfterDot)
  return (Math.floor(this * multiplier) / multiplier).toString()
}

Vue.prototype.$config = Object.freeze(production)

Vue.config.productionTip = false
Vue.use(VueI18n)

Vue.prototype.$digitsAfterDot = 2

const i18n = new VueI18n({
  locale: 'en',
  messages
})

new Vue({
  store,
  render: h => h(App),
  i18n,
  router
}).$mount('#app')

<template>
    <div class="container screen rst-statistics" >
        <h1>{{ $t("messages.tokenStatistics") }}</h1>
        <div class="screen_block screen_block_column">
            <div>
                <div class="block block_statistics block_statistics_first ">
                    <h2>{{ $t("messages.titleTokens") }}</h2>
                    <h1><div class="rst">rst</div>{{ Number.parseFloat(tokensBalanceFormated).toFixed($digitsAfterDot) }}</h1>
                    <router-link tag="button" :to="{ name: 'staking' }" class="button-collect">{{ $t("messages.createLiquidity") }}</router-link>
                </div>
                <ul class="info-list">
                    <li>
                        <div>{{ $t("messages.priceToken") }}:</div>
                        <div>{{ Number.parseFloat(tokenPrice).toFixed($digitsAfterDot) }} BUSD</div>
                    </li>
                    <li>
                        <div>{{ $t("messages.priceChange") }}:</div>
                        <div>{{ Number.parseFloat(tokenPriceChange).toFixed($digitsAfterDot) }}%</div>
                    </li>
                </ul>
                <div class="block block_statistics_links">
                    <a href="javascript:void(0);" class="button" @click="watchTokenAsset">{{ $t("messages.addTokenToWallet") }}</a>                    
                    <a href="https://pancakeswap.finance/swap?inputCurrency=0xD70B38dEDD42B4c22A7e0D8A176fb0957C5fbAC3" target="_blank" class="link">{{ $t("messages.goToExchange") }}</a>
                </div>
            </div>
            <div  class="screen_block two_blocks">
                <div class="card-statistics">
                    <div class="card-body">
                        <div class="block block_statistics">
                            <h2>{{ $t("messages.titleLiquidityTokens") }}</h2>
                            <h1><div class="rst">rst <span>lp</span></div>{{ Number.parseFloat(liquidityTokensBalanceFormated).toFixed($digitsAfterDot) }}</h1>
                            <div class="h3">
                                {{ $t("messages.feesAlert") }}
                            </div>
                            <router-link tag="button" to="staking#staking" class="button-collect">{{ $t("messages.Stake") }}</router-link>
                        </div>
                    </div>
                </div>
                <div class="card-statistics">
                    <div class="card-body">
                        <div class="block block_statistics">
                            <h2>{{ $t("messages.titleTokens") }}</h2>
                            <h1><div class="rst">rst</div>{{ Number.parseFloat(availableRewardFormatted).toFixed($digitsAfterDot) }}</h1>
                            <div class="h3">
                                {{ $t("messages.yourCurrentStakingEarnings") }}
                            </div>
                            <button @click="collectStakingRewards" class="button-collect">{{ $t("messages.Collect") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
 import { mapState, mapGetters } from 'vuex'
 import { ethers } from 'ethers'

const PERCENT_MULTIPLIER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const ZERO_BN = ethers.BigNumber.from('0')
const ONE_BN = ethers.BigNumber.from('1')

export default {
  name: 'StatisticsPage',
   computed: {
       ...mapGetters(['tokensBalanceFormated', 'tokensBalanceBigNumber']),
       ...mapState('liquidity', ['currencyReserve', 'tokensReserve']),
       ...mapState('staking', ['userToContractLiquidityTokensAllowance']),
       ...mapGetters('staking', ['stakedFormatted', 'availableRewardFormatted']),
       ...mapGetters('liquidity', ['liquidityTokensBalanceFormated', '__userLiquidityPortionPercent']),
        tokensPerCurrency () {
            const tokensReserve = this.tokensReserve.eq(ZERO_BN) ? ONE_BN : this.tokensReserve;
            return this.currencyReserve.mul(PERCENT_MULTIPLIER).div(tokensReserve)
        },
      tokenPrice: function () {
          return Number.parseFloat(ethers.utils.formatEther(this.tokensPerCurrency)) / Number.parseFloat(ethers.utils.formatEther(PERCENT_MULTIPLIER))
      },
      tokenPriceChange: function () {
            const originalPrice = 1 / 10
            return this.tokenPrice / originalPrice * 100 - 100
        },
      liquidityToUsd: function () {
          return (Number.parseFloat(ethers.utils.formatEther(this.currencyReserve)) + Number.parseFloat(ethers.utils.formatEther(this.tokensReserve)) * this.tokenPrice).toFixed(this.$digitsAfterDot)
      },
      liquidityTokensPoolToUsd : function () {
          return (this.liquidityToUsd * this.__userLiquidityPortionPercent.toNumber() / Math.pow(10, 10)).toFixed(this.$digitsAfterDot)
      },
      isAllowsToStake () {
            return this.userToContractLiquidityTokensAllowance.gte(this.tokensBalanceBigNumber)
        }
   },
   methods: {
      collectStakingRewards: function () {
          this.$contract.collectStakingRewards().then(tx => {
              tx.wait().then(this.onTransactionDone)
          })
       },
      watchTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.TOKEN,
                    symbol: 'RST',
                    decimals: 18
                }
            }
        })
      },
      stake: function () {
            this.$contract.stake(this.tokensBalanceBigNumber).then(this.onTransactionDone)
        }
   }
}
</script>

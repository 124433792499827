<template>
    <li v-if="isWalletConnectted">
        <a v-on:click="connectWallet('ethereum')">{{ buttonContent }}</a>
    </li>
    <li v-else>
        <a v-on:click="connectWallet('ethereum')">{{ $t("messages.Connect") }}</a>
    </li>
</template>

<script>
import { waitingProvider } from '@/services/provider.utils'

const WALLET_KEY = 'WALLET'
const ZERO_ADDRESS = '0x' + '0'.repeat(40)

function getShorterAddress (address, tailsLength = 4) {
    return address.substring(0, tailsLength) + '...' + address.substring(address.length - tailsLength, address.length)
}

export default {
    name: 'ConnectButtonComponent',
    data: function () {
        return {
            isWalletConnectted: false,
            buttonContent: getShorterAddress(ZERO_ADDRESS)
        }
    },
    methods: {
        connectWallet: async function (walletKey) {
            const walletProvider = await waitingProvider(walletKey)
            const currentAccount = await this.$contract.connectWalletProviderAndGetCurrentAddress(walletProvider)
            this.isWalletConnectted = true
            this.buttonContent = getShorterAddress(currentAccount)
            localStorage.setItem(WALLET_KEY, walletKey)

            this.$emit('onAccountConnected')

            this.$contract.onAccountsChanged(() => this.connectWallet(walletKey))
        },
        checkCachedWallet: function () {
            const cachedWallet = localStorage.getItem(WALLET_KEY)

            if (cachedWallet) {
                this.connectWallet(cachedWallet)
            }
        }
    }
}
</script>
<template>
    <div class="staking">
        <h3>{{ $t("messages.stakingDescription") }}</h3>
        <div class="card card_select">
            <div class="card-body">
                <div class="container-select-block">
                    <div class="btn" v-bind:class="{ active: isStaking }" v-on:click="enableStakingPanel">{{ $t("messages.Stake") }}</div>
                    <div class="btn" v-bind:class="{ active: !isStaking }" v-on:click="enableUnstakingPanel">{{ $t("messages.Unstake") }}</div>
                </div>
                <add-staking-component v-if="isStaking" />
                <remove-staking-component v-else />
            </div>
        </div>
    </div>
</template>

<script>
import AddStakingComponent from './AddStakingComponent.vue'
import RemoveStakingComponent from './RemoveStakingComponent.vue'
export default {
    components: { AddStakingComponent, RemoveStakingComponent },
    name: 'StakingPanelComponent',
    data: function () {
        return {
            isStaking: true
        }
    },
    methods: {
        enableStakingPanel: function () {
            this.isStaking = true
        },
        enableUnstakingPanel: function () {
            this.isStaking = false
        }
    }
}
</script>
export const messages = {
    en: {
        messages: {
            Liquidity: 'Liquidity',
            Staking: 'Staking',
            liquidityDescription: `If you have BUSD coins and RST tokens, you can add them
            to the liquidity pool on the PancakeSwap exchange for the BUSD/RST
            pair and receive RST-LP (Cake LP) liquidity tokens. Using liquidity
            tokens, you can stake and receive new RST tokens. You can add or
            remove liquidity at any time using both the Infinity Income  interface
            and the PancakeSwap exchange.`,
            myPoolOfTokens: 'My pool of tokens',
            pairAddress: 'Pair address',
            tokenAddress: 'RST address',
            getToken: 'Get RST',
            addTokenToWallet: 'Add RST to Metamask',
            addLpTokenToWallet: 'Add RST-LP to Metamask',
            stakedTokens: 'Staked tokens',
            Earned: 'Earned',
            stakingProfitability: 'APY',
            feeAlert: 'Attention! Remember about blockchain fees! You should always keep a small amount of BNB (~ 0.01 BNB) to pay the transaction fee',
            tokensEarned: 'RST Earned',
            stakingDescription: `If you have RST-LP liquidity tokens (Cake LP), you can stake them to get new RST coins.
            You can withdraw liquidity tokens at any time.`,
            Collect: 'Collect',
            Connect: 'Connect',
            Stake: 'Stake',
            Unstake: 'Unstake',
            Amount: 'Amount',
            Menu: 'Menu',
            tokenStatistics: 'RST statistics',
            titleTokens: 'RST Tokens',
            titleLiquidityTokens: 'RST-LP Tokens',
            priceToken: 'Price RST',
            priceChange: 'Price change',
            goToExchange: 'Go to PancakeSwap',
            feesAlert: `Don't forget about the blockchain network fees ! You must have
            approximately 0,01 BNB in your wallet to pay for transactions.`,
            yourCurrentStakingEarnings: 'You current staking earnings.',
            Add: 'Add',
            Remove: 'Remove',
            Approve: 'Approve',
            footerDescription: 'Staking pool is a new generation decentralized economic system',
            createLiquidity: 'Create liquidity',
            addressSmartContract: 'Smart contract address'
        }
    },
       ru: {
        messages: {
            Liquidity: 'Ликвидность',
            Staking: 'Стейкинг',
            liquidityDescription: `Если у вас есть монеты BUSD и токены RST, вы можете добавить их в пул ликвидности на бирже PancakeSwap для пары BUSD/RST и получить токены ликвидности RST-LP (Cake LP). Используя токены ликвидности, вы можете стейкать токены ликвидности и получать в качестве награды токены RST. Вы можете добавить или забрать ликвидность в любое время, используя интерфейс Staking Pool и биржу PancakeSwap.`,
            myPoolOfTokens: 'Мой пул токенов',
            pairAddress: 'Адрес пары',
            tokenAddress: 'Адрес RST',
            getToken: 'Получить RST',
            addTokenToWallet: 'Добавить RST в Metamask',
            addLpTokenToWallet: 'Добавить RST-LP токен в Metamask',
            stakedTokens: 'Застейканные токены',
            Earned: 'Заработано',
            stakingProfitability: 'Доходность стейкинга',
            feeAlert: 'Внимание! Не забывайте про комиссию блокчейна! Всегда держите примерно 0,01 BNB для оплаты комиссии сети.',
            tokensEarned: 'Заработано RST',
            stakingDescription: `Если у вас есть RST-LP токены (Cake LP), вы можете застейкать их и получать новые RST токены. Ликвидность можно забрать в любое время.`,
            Collect: 'Собрать',
            Connect: 'Подключить',
            Stake: 'Стейкать',
            Unstake: 'Отстейкать',
            Amount: 'Сумма',
            Menu: 'Меню',
            tokenStatistics: 'Статистика RST',
            titleTokens: 'Токены RST',
            titleLiquidityTokens: 'Токены RST-LP',
            priceToken: 'Цена RST',
            priceChange: 'Изменение Цены',
            goToExchange: 'Перейти на PancakeSwap',
            feesAlert: `Не забывайте о комиссии блокчейна! У вас должно быть примерно 0,01 BNB на вашем кошельке для оплаты транзакций.`,
            yourCurrentStakingEarnings: 'Ваш текущий доход от стейкинга',
            Add: 'Добавить',
            Remove: 'Убрать',
            Approve: 'Подтвердить',
            footerDescription: 'Staking pool - децентрализованная экономическая система нового поколения.',
            createLiquidity: 'Создать ликвидность',
            addressSmartContract: 'Адрес смарт-контракта'
        }
    }
}

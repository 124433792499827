<template>
    <div class="content-select">
        <ul class="info-list">
            <li>
                <div>RST :</div>
                <div>{{ Number.parseFloat(formatedCurrentUserTokensBalance).toFixed($digitsAfterDot) }}</div>
            </li>
            <li>
                <div>BUSD :</div>
                <div>{{ Number.parseFloat(formatedCurrentUserCurrencyBalance).toFixed($digitsAfterDot) }}</div>
            </li>
        </ul>
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}: </div>
                <div class="value">{{ Number.parseFloat(formatedCurrentUserLiquidityTokensBalance).toFixed($digitsAfterDot) }} RST-LP</div>
            </div>
            <div class="input-group"><input v-model="flipTokensToRemoveLiquidity" id="bnbDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control">
            </div>
        </div>
        <div class="container-select-amount">
            <button class="btn" @click="setAmountToRemoveByPercent25">25%</button>
            <button class="btn" @click="setAmountToRemoveByPercent50">50%</button>
            <button class="btn" @click="setAmountToRemoveByPercent75">75%</button>
            <button class="btn" @click="setAmountToRemoveByPercent100">100%</button>
        </div>
        <div class="content-select-footer">
            <button @click="removeLiquidity" v-if="sendTransactionButtonContent">{{ $t("messages.Remove") }}</button>
            <button @click="removeLiquidity" v-else>{{ $t("messages.Approve") }}</button>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapState, mapGetters } from 'vuex'

const ZERO_BN = ethers.BigNumber.from('0')
const PERCENTS_DIVIDER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const DEADLINE_IN_MINUTES = 20
const MIN_DIFF_PERCENT = 0.05
const PERCENT_MULTIPLIER = 100

export default {
    name: 'RemoveLiquidityComponent',
    data: function () {
        return {
            flipTokensToRemoveLiquidity: 0
        }
    },
    watch: {
        flipTokensToRemoveLiquidity: function (val) {
            if (typeof val == 'string') {
                if (val[0] == '0' && val.length > 1) {
                    this.flipTokensToRemoveLiquidity = Number.parseFloat(val.slice(1))
                } else {
                    this.flipTokensToRemoveLiquidity = Number.parseFloat(val)
                }
            } else {
                this.flipTokensToRemoveLiquidity = val
            }
        }
    },
    computed: {
        ...mapState('liquidity', ['currentUserLiquidityTokensBalance', 'userToSwapLiquidityTokensAllowance', 'liquidityTokensAmount']),
        ...mapGetters('liquidity', ['currentUserCurrencyBalance', 'currentUserTokensBalance']),
        formatedCurrentUserCurrencyBalance () {
            return ethers.utils.formatEther(this.currentUserCurrencyBalance)
        },
        formatedCurrentUserTokensBalance () {
            return ethers.utils.formatEther(this.currentUserTokensBalance)
        },
        formatedCurrentUserLiquidityTokensBalance () {
            return ethers.utils.formatEther(this.currentUserLiquidityTokensBalance)
        },
        formatedCurrentUserLiquidityTokensBalanceToPercents () {
            return ethers.utils.formatEther(this.currentUserLiquidityTokensBalance.sub(100))
        },
        sendTransactionButtonContent () {
            return this.userToSwapLiquidityTokensAllowance.gte(ethers.utils.parseEther(Number.parseFloat(this.flipTokensToRemoveLiquidity).toFixed(18)))
        }
    },
    methods: {
        removeLiquidity: function () {
            if (this.userToSwapLiquidityTokensAllowance.gte(ethers.utils.parseEther(Number.parseFloat(this.flipTokensToRemoveLiquidity).toFixed(18)))) {
                this.__removeLiquidity()
            } else {
                this.__lpTokensApprove()
            }
        },
        __removeLiquidity: async function () {
            let liquidityPercent
            try {
                liquidityPercent = this.currentUserLiquidityTokensBalance.eq(ZERO_BN) ? ZERO_BN : ethers.utils.parseEther(Number.parseFloat(this.flipTokensToRemoveLiquidity).toFixed(18)).mul(PERCENTS_DIVIDER).div(this.currentUserLiquidityTokensBalance)
            } catch (e) {
                console.error(e)
                liquidityPercent = ethers.BigNumber.from('0')
            }

            const liquidity = ethers.utils.parseEther(Number.parseFloat(this.flipTokensToRemoveLiquidity).toFixed(18))
            let amountTokensMin = this.currentUserTokensBalance.mul(liquidityPercent).div(PERCENTS_DIVIDER)
            amountTokensMin = amountTokensMin.sub(amountTokensMin.mul(MIN_DIFF_PERCENT * PERCENT_MULTIPLIER).div(100 * PERCENT_MULTIPLIER))
            let amountCurrencyMin = this.currentUserCurrencyBalance.mul(liquidityPercent).div(PERCENTS_DIVIDER)
            amountCurrencyMin = amountCurrencyMin.sub(amountCurrencyMin.mul(MIN_DIFF_PERCENT * PERCENT_MULTIPLIER).div(100 * PERCENT_MULTIPLIER))
            const deadline = Number.parseInt(Date.now() / 1000) + DEADLINE_IN_MINUTES * 60

            console.log(ethers.utils.formatEther(amountTokensMin), ethers.utils.formatEther(amountCurrencyMin))

            if (liquidity.lte(this.currentUserLiquidityTokensBalance)) {
                const tx = await this.$contract.removeLiquidityETH(liquidity, amountTokensMin, amountCurrencyMin, deadline)
                await tx.wait()
                this.flipTokensToRemoveLiquidity = 0
                this.$parent.$emit('onTransactionDone')
            } else {
                this.$store.commit('setWarningFlag', true)
                setTimeout(() => this.$store.commit('setWarningFlag', false), 5000)
            }
        },
        __lpTokensApprove: async function () {
            const tx = await this.$contract.lpTokensApprove(this.liquidityTokensAmount)
            await tx.wait()
            this.$parent.$emit('onTransactionDone')
        },
        __setAmountToRemoveByPercent: function (percent) {
            const amountToRemove = Number.parseFloat(this.formatedCurrentUserLiquidityTokensBalanceToPercents) * percent
            this.$set(this, 'flipTokensToRemoveLiquidity', amountToRemove)
        },
        setAmountToRemoveByPercent25: function () {
            this.__setAmountToRemoveByPercent(0.25)
        },
        setAmountToRemoveByPercent50: function () {
            this.__setAmountToRemoveByPercent(0.5)
        },
        setAmountToRemoveByPercent75: function () {
            this.__setAmountToRemoveByPercent(0.75)
        },
        setAmountToRemoveByPercent100: function () {
            this.__setAmountToRemoveByPercent(1)
        }
    }
}
</script>